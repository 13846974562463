.root {
  background-image: url('../../assets/herosection/image10.jpg');
  height: 300px;
  width: calc(100% - 4rem);
  background-size: cover;
  /* background-position: center -450px; */
  /* background-position: center -250px; */
  /* margin-bottom: 2rem; */
  background-position: center;
  overflow: hidden;
  margin: 0 2rem 2rem 2rem;

  @media (min-width: 769px) {
    background-position: center -250px;
    margin: 0 auto 2rem auto;
  }
}
