.root {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  /* box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.5), 0 3px 6px rgba(0, 0, 0, 0.5); */
}

.textShadow {
  font-weight: bold;
  /* text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9), 2px 2px 8px rgba(255, 255, 255, 0.9); */
  color: ghostwhite;
}

.buttonWidth {
  width: calc(100% - 4rem);
}

.parent {
  & > :first-child {
    order: 1;
  }
  & > :nth-child(2) {
    order: 3;
  }
  & > :nth-child(3) {
    order: 5;
  }
  & > :nth-child(4) {
    order: 2;
    margin: 0;
  }
  & > :nth-child(5) {
    order: 4;
    margin: 0;
  }
  & > :nth-child(6) {
    order: 6;
    margin: 0;
  }

  @media (min-width: 1024px) {
    & > :first-child {
      order: 1;
    }
    & > :nth-child(2) {
      order: 2;
    }
    & > :nth-child(3) {
      order: 3;
    }
    & > :nth-child(4) {
      margin: 0;
      order: 4;
    }
    & > :nth-child(5) {
      order: 5;
      margin: 0;
    }
    & > :nth-child(6) {
      order: 6;
      margin: 0;
    }
  }
}
